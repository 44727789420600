import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { LoginRegisterFormComponent } from '../login-register-form/login-register-form.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AnalyticsService } from '@services/analytics.service';
import { WINDOW } from '@ng-web-apis/common';
import { StorageService } from '@services/storage.service';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, filter, map, pluck, takeUntil } from 'rxjs/operators';
import { EnvConfigService } from '@services/env-config.service';
import { TranslateService } from '@shared/translate/translate.service';
import { AppStateService } from '@services/app-state.service';
import { FrkBreakpoint, ResponsiveService } from '@services/responsive.service';
import { LoginService } from '@services/login.service';
import { InUserType } from '@types';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from '@utils/logger';
import { WindowService } from '@services/window.service';
import { replaceTokens } from '@utils/text/string-utils';
import { DOCUMENT } from '@angular/common';
import { getModelFromRef, getMenus } from '@utils/pagemodel-utils';

const logger = Logger.getLogger('HeaderComponent');

export interface DataWindow extends Window {
  // TODO: define type for dataLayer
  dataLayer: any[];
}
@Component({
  selector: 'ft-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  public menuItems;
  public menu;
  // public channelName: string = Constant.channelName;
  public loginRegisterData;
  public isSearchBox = false;
  public isLoginBox = false;
  public menuOpen = false;
  public searchText = '';
  public baseUrl = '';
  public isMobile: boolean;
  public isLoggedIn: boolean;
  public userType: string;
  public showAutoSuggestions = false;
  public triggerChange = false;
  public isIpad = false;
  public isMobileMin = false;
  public feedbackModalVisible: boolean;
  public feedbackThankYou = false;

  private searchForm: any = {
    isActive: false,
    value: '',
    error: false,
  };
  private menuList;
  private topMenuList;
  private mainMenuList = [];
  private sessionEnd;
  private sessionTimeout = 120000; // 2min in ms
  private accountsBaseUrl = '';
  private queryParams: Params;
  private alertModalOpt: NgbModalOptions = {
    size: 'lg',
    backdrop: 'static',
    animation: true,
  };
  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild('searchBarInput') searchBarInput: ElementRef;
  @ViewChild('selectList', { static: false }) selectList: ElementRef;
  @ViewChild('mobheader', { static: false }) mobileHeader: ElementRef;
  @ViewChild('mobmenu', { static: false }) mobileMenu: ElementRef;
  // @ViewChild('gridContentArrow', { static: false }) gridContentArrow: ElementRef;
  @ViewChild(LoginRegisterFormComponent)
  loginRegisterForm!: LoginRegisterFormComponent;
  @ViewChild('feedbackModal') feedbackModal;
  @ViewChild('sessionModal') sessionModal;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 needs mor than 7 parameters in constructor
    private renderer: Renderer2,
    private el: ElementRef,
    private cb: ChangeDetectorRef,
    private router: Router,
    private analyticsService: AnalyticsService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private envConfig: EnvConfigService,
    private translateService: TranslateService,
    private appStateService: AppStateService,
    private responsiveService: ResponsiveService,
    private loginService: LoginService,
    private changeDetector: ChangeDetectorRef,
    private modalService: NgbModal,
    private windowService: WindowService,
    @Inject(DOCUMENT) private documentRef: Document,
    @Inject(WINDOW) private windowRef: DataWindow
  ) {
    this.responsiveService
      .isHandheldMini$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
      });
    this.responsiveService
      .isMobileMin$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((isMobileMin: boolean) => {
        this.isMobileMin = isMobileMin;
      });
  }

  ngOnInit(): void {
    this.initMenu();
    // Track Window Resize (Throttled)
    this.windowService
      .getResizeObs$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.changeDetector.detectChanges();
      });

    this.baseUrl = this.envConfig.getEnvConfig()?.baseUrl;
    this.accountsBaseUrl = this.envConfig.getEnvConfig()?.ftiAccountsUrl;
    this.loginRegisterData = this.page
      ?.getComponent('header-content')
      ?.getComponent('IND-login-register-form');
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.searchText = this.queryParams?.query;

    // Set alert modal for logout button
    this.loginService
      .getUserFeedbackDisplay$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((feedback) => {
        this.feedbackModalVisible = feedback;
        if (feedback) {
          this.modalService.open(this.feedbackModal, this.alertModalOpt);
        }
      });
    // Set alert modal for session end
    this.loginService
      .getLoginSession$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((sessionLogin) => {
        if (!sessionLogin) {
          this.modalService.open(this.sessionModal, this.alertModalOpt);
          this.sessionEnd = setTimeout(() => {
            logger.debug('Logging out after timeout');
            this.loginService.logOut();
          }, this.sessionTimeout);
        }
      });

    this.storageService
      .retrieve('feedbackSubmitted', true)
      .then((isFeedbackSubmitted) => {
        if (isFeedbackSubmitted) {
          this.feedbackThankYou = true;
          this.storageService.remove('feedbackSubmitted', true);
          setTimeout(() => {
            this.feedbackThankYou = false;
          }, 20000);
        }
      });
  }

  ngAfterViewInit(): void {
    this.loginRegisterForm?.defaultRadioChecked();
    this.loginService
      .isLoggedIn$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((loggedIn: boolean) => {
        this.isLoggedIn = loggedIn;
        this.changeDetector.detectChanges();
        this.loginService
          .loggedInUserType$()
          ?.pipe(takeUntil(this.unsubscribe$))
          .subscribe((userType: InUserType) => {
            this.userType = userType;
            // Mobile menu element needs to be generated in subscription, as userType is used in it.
            const navToggler = this.documentRef.querySelector(
              '.mobileView .navbar-toggler'
            );
            navToggler.addEventListener('click', (e) => {
              const headerEl = this.documentRef.querySelector('#mobileHeader');
              this.documentRef
                .querySelector('.nav-overlay')
                .classList.toggle('d-none');
              this.documentRef
                .querySelector('#mobContainer')
                .classList.toggle('fullHeight');
              headerEl.classList.toggle('open');
            });
            const navOverlay = this.documentRef.querySelector('.nav-overlay');
            navOverlay.addEventListener('click', function (e) {
              const headerEl = this.documentRef.querySelector('#mobileHeader');
              this.classList.toggle('d-none');
              this.documentRef
                .querySelector('#mobContainer')
                .classList.toggle('fullHeight');
              headerEl.classList.toggle('open');
            });
            const menuElem = this.documentRef.createElement('div');
            menuElem.innerHTML = `<a tabindex="0" class="nav-link" data-event-name="Event All pages" data-category="Top Navigation" data-action="Logo CLick" data-label="https://www.franklintempletonindia.com"
      href="/">
        <img class="mobile-menu-logo" src="/assets/images/fti_logo_white.png" alt="Franklin Templeton Investments" title="Franklin Templeton Investments"/>
    </a>`;
            const mainMenu = this.menuList;
            const topMenu = this.topMenuList;
            Object.assign(menuElem, { className: 'list-group panel' });

            mainMenu.forEach((item) => {
              let name: any = item.name;
              name = name
                .toLowerCase()
                .trim()
                .replace(/[&'?\/]+/g, '');
              name = name.replaceAll(' ', '-');
              menuElem.appendChild(
                this.populateMenu(item, 'fti-nav_mainMenu', false, false)
              );
              if (item.childMenuItems.length !== 0) {
                const divElem = this.documentRef.createElement('div');
                Object.assign(divElem, {
                  className: 'secondaryNav collapse',
                  id: 'fti-nav_' + name,
                });

                item.childMenuItems.forEach((subMenu) => {
                  // Secondary menu
                  let subName: any = subMenu.name;
                  subName = subName
                    .toLowerCase()
                    .trim()
                    .replace(/[&'?\/]+/g, '');
                  subName = subName.replaceAll(' ', '-');

                  if (subMenu.childMenuItems.length !== 0) {
                    const divElem2 = this.documentRef.createElement('div');
                    Object.assign(divElem2, {
                      className: 'tertiaryNav list-group-submenu collapse',
                      id: 'fti-nav_' + subName,
                    });

                    subMenu.childMenuItems.forEach((childMenu) => {
                      // tertiary menu
                      divElem2.appendChild(
                        this.populateMenu(
                          childMenu,
                          '#fti-nav_' + subName,
                          true,
                          false
                        )
                      );
                    });
                    divElem.appendChild(
                      this.populateMenu(
                        subMenu,
                        '#fti-nav_' + name,
                        false,
                        false
                      )
                    );
                    divElem.append(divElem2);
                  } else {
                    divElem.appendChild(
                      this.populateMenu(
                        subMenu,
                        '#fti-nav_' + name,
                        true,
                        false
                      )
                    );
                  }
                  menuElem.appendChild(divElem);
                });
              }
            });

            topMenu.forEach((item) => {
              let name: any = item.name;
              name = name
                .toLowerCase()
                .trim()
                .replace(/[&'?\/]+/g, '');
              name = name.replaceAll(' ', '-');
              if (item.childMenuItems.length !== 0) {
                const divElem = this.documentRef.createElement('div');
                Object.assign(divElem, {
                  className: 'secondaryNav collapse',
                  id: 'fti-nav_' + name,
                });
                menuElem.appendChild(
                  this.populateMenu(item, 'fti-nav_mainMenu', false, true)
                );
                item.childMenuItems.forEach((subMenu) => {
                  // Secondary menu
                  let subName: any = subMenu.name;
                  subName = subName
                    .toLowerCase()
                    .trim()
                    .replace(/[&'?\/]+/g, '')
                    .replaceAll(' ', '-');
                  divElem.appendChild(
                    this.populateMenu(subMenu, '#fti-nav_' + name, true, true)
                  );
                  menuElem.appendChild(divElem);
                });
              } else {
                menuElem.appendChild(
                  this.populateMenu(item, 'fti-nav_mainMenu', true, true)
                );
              }
            });
            const countryMenuHtml = `Change Country
        <span class="icon-down icon icon-fti_rightArrow float-start"></span>
        <img class="nav-flag" src="/assets/images/fti_country_india.png" alt="India, click to change location " title="India, click to change location"/>`;
            const anchorElem = this.documentRef.createElement('a');
            const ftUrl = this.translateService.instant(
              'ftiCommon.countryMenuUrl'
            );
            Object.assign(anchorElem, {
              className: 'analytics-link list-group-item countryUL collapsed',
              href: ftUrl,
            });
            anchorElem.innerHTML = countryMenuHtml;
            menuElem.appendChild(anchorElem);
            this.documentRef.querySelector('.mobileMenu').appendChild(menuElem);
            this.changeDetector.detectChanges();
          });
      });

    fromEvent<string>(this.searchBarInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          if (event.keyCode === 13) {
            this.navigateToSearchResults();
            return;
          }
          return event;
        }),
        filter(Boolean),
        pluck('target', 'value'),
        debounceTime(500),
        filter((term) => String(term).length >= 3 || term === ''),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((term: string) => {
        this.showAutoSuggestions = false;
        if (term) {
          this.showAutoSuggestions = true;
          this.triggerChange = !this.triggerChange;
        }
        this.cb.detectChanges();
      });

    // Code to show hide the parent options
    const listGroupItem = this.documentRef.querySelectorAll('.list-group-item');
    listGroupItem.forEach((response) => {
      response.addEventListener('click', function (e) {
        if (this.parentElement.classList.contains('list-group')) {
          listGroupItem.forEach((responseData) => {
            if (
              this.getAttribute('href') !== responseData.getAttribute('href')
            ) {
              if (!responseData.classList.contains('collapsed')) {
                responseData.classList.add('collapsed');
                responseData.nextElementSibling.classList.remove('show');
              }
            }
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private populateMenu(
    item,
    parentID,
    hasNoChild,
    isTopMenu
  ): HTMLAnchorElement {
    let name: any = item.name;
    name = name
      .toLowerCase()
      .trim()
      .replace(/[&'?\/]+/g, '');
    name = name.replaceAll(' ', '-');
    const anchorElem = this.documentRef.createElement('a');
    let optObj;
    let optDatasetObj;
    if (hasNoChild) {
      const className =
        'analytics-link no-childmenu list-group-item subNav-header navCollapsed collapsed' +
        this.showHideLink(item);
      optObj = {
        className,
        href: this.getUrl(item),
      };
      optDatasetObj = {
        bsParent: parentID,
        eventName: 'Event All pages',
        category: 'Top Navigation',
        action: item.name,
        label: 'click',
      };
      if (isTopMenu) {
        anchorElem.innerHTML = `<span class="icon-down icon icon-fti_rightArrow float-start"></span>
        <span class="icon-down icon icon-fti_downArrow float-start"></span>${item.name}`;
      } else {
        anchorElem.innerHTML = `${item.name}`;
      }
      // Adding dataTarget
      const reqParam: string =
        item?.parameters['Request parameter (key=value)'];
      if (reqParam && reqParam.includes('dataTarget')) {
        logger.debug('Set dataTarget options', reqParam);
        anchorElem.setAttribute('data-bs-target', '#' + reqParam.split('=')[1]);
        anchorElem.setAttribute('data-bs-toggle', 'modal');
      }
      anchorElem.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        const href = this.getUrl(item);
        // Adding target blank window
        if (this.isNewPage(item)) {
          this.windowRef.open(href, '_blank');
        } else {
          // When href contains 'http:// or httsp:// do not ruse route method
          if (/http(s?):\/\//.test(href)) {
            this.windowRef.location.href = href;
          } else {
            this?.router?.navigateByUrl(href).then(() => {
              logger.debug('Routing to:', href);
            });
          }
        }
      });
    } else {
      const className =
        'analytics-link list-group-item subNav-header navCollapsed collapsed' +
        this.showHideLink(item);
      optObj = {
        className,
        href: '#fti-nav_' + name,
      };
      optDatasetObj = {
        bsToggle: 'collapse',
        bsParent: parentID,
        eventName: 'Event All pages',
        category: 'Top Navigation',
        action: item.name,
        label: 'click',
      };
      anchorElem.innerHTML = `<span class="icon-down icon icon-fti_rightArrow float-start"></span>
        <span class="icon-down icon icon-fti_downArrow float-start"></span>${item.name}`;
    }
    Object.assign(anchorElem, optObj);
    Object.assign(anchorElem.dataset, optDatasetObj);
    return anchorElem;
  }

  private showHideLink(menuItem): string {
    const hideLink = this.advisorHideLink(menuItem);
    const showLink = this.advisorShowLink(menuItem);
    let className = '';
    if (hideLink) {
      className += ' ' + hideLink;
    }
    if (showLink) {
      className += ' ' + showLink;
    }
    return className;
  }

  private getMainMenuItems(data) {
    if (this.mainMenuList.length > 0) {
      return this.mainMenuList;
    }
    const arr = [];

    data?.childMenuItems.forEach((value) => {
      const result = this.getSteps(value?.childMenuItems);
      arr.push({
        name: value.name,
        childMenuItems: result.data.length
          ? result.data
          : value?.childMenuItems,
        links: value.links,
      });
    });
    this.mainMenuList = arr;
    return arr;
  }

  private getSteps(data) {
    let arr = [];
    const arrFinal = [];
    let count = 0;
    let counter = 0;
    let flag = false;

    data.forEach((value) => {
      if (value.links?.site) {
        counter++;
      }
    });

    data.forEach((value) => {
      if (value.links?.site) {
        flag = true;
        count++;
        arr.push(value);

        if (arr.length === 9) {
          arrFinal.push(arr);
          arr = [];
        }
        if (count === counter) {
          arrFinal.push(arr);
          arr = [];
        }
      } else {
        if (flag) {
          arrFinal.push(value);
        }
      }
    });

    return { data: arrFinal };
  }

  private initMenu() {
    this.menuItems = getMenus(this.page, this.component);
    const headerItems = {
      topMenuItems: this.menuItems?.siteMenuItems[0],
      mainMenuItems: this.getMainMenuItems(this.menuItems?.siteMenuItems[1]),
    };
    this.menuList = this.menuItems?.siteMenuItems[1]?.childMenuItems;
    this.topMenuList = this.menuItems?.siteMenuItems[0]?.childMenuItems;
    this.menu = headerItems;
  }

  // for overlay button in XM
  get menuModel() {
    return getModelFromRef(this.page, this.component, 'menu');
  }
  get isPreview() {
    return this.page.isPreview();
  }

  public doSearch() {
    if (this.searchForm.value === '') {
      this.searchForm.error = true;
    }
  }
  public navigateToSearchResults(): void {
    this.router.navigate(['/search'], {
      queryParams: { query: this.searchText, filter: 'all' },
    });
  }

  public showHidePopUp($event): void {
    $event.stopPropagation();
  }

  // Search in mobile view (iphone)
  public callSearchBox(): void {
    this.isSearchBox = !this.isSearchBox;
  }

  // Login in mobile view
  public callLoginBox(): void {
    this.isLoginBox = !this.isLoginBox;
  }

  showMobileMenu() {
    return !this.menuOpen;
  }

  public goToFeedback() {
    this.windowRef.open('/feedback', '_self');
  }

  public continueSession() {
    logger.debug('Continuing logged in session');
    clearTimeout(this.sessionEnd);
    this.loginService.resetSession();
    this.modalService.dismissAll();
  }

  public ignoreSession() {
    logger.debug('Ignoring session timeout. Time to logout still ticking.');
    this.modalService.dismissAll();
  }

  public signOut(justSignOut?: boolean): void {
    if (justSignOut) {
      this.loginService.logOut();
      return;
    }
    this.storageService.retrieve('userFeedbackStatus').then((userFeedback) => {
      if (userFeedback === 'false' || userFeedback === '') {
        this.loginService.setUserFeedbackDisplay(true);
      } else {
        this.loginService.logOut();
      }
    });
  }

  public myAccounts(): void {
    this.loginService.myAccountsLink();
  }

  public closeFeedbackModal(event) {
    this.loginService.setUserFeedbackDisplay(event);
  }

  public advisorHideLink(menuItem): string {
    let className = '';
    const params = menuItem.parameters;
    if (
      params &&
      params['Request parameter (key=value)'].indexOf(
        'appAdvisorHideLinksSection'
      ) !== -1
    ) {
      if (this.userType && this.userType !== InUserType.INVESTOR) {
        className = 'appAdvisorHideLinksSection';
      }
    }

    return className;
  }

  public advisorShowLink(menuItem): string {
    let className = '';
    const params = menuItem.parameters;
    if (
      params &&
      params['Request parameter (key=value)'].indexOf(
        'appAdvisorLinksSection'
      ) !== -1
    ) {
      className = 'appAdvisorLinksSection';

      if (this.userType && this.userType !== InUserType.INVESTOR) {
        const allItems = this.documentRef.querySelectorAll<HTMLElement>(
          '.appAdvisorLinksSection'
        );
        allItems.forEach((response) => {
          response.style.display = 'block';
        });
      }
    }
    return className;
  }

  public getUrl(menu?: any): string {
    // We can use both, ENV tokens and app=redirectGuestUrl parameter in menu URLs.
    // Token functionality gives more flexibility.
    // app=redirectGuestUrl param can be set only one URL
    if (
      menu.links?.site &&
      menu.parameters['Request parameter (key=value)'] ===
        'app=redirectGuestUrl'
    ) {
      return (
        this.appStateService.getftiAccountsUrl() +
        replaceTokens(menu.links?.site.href, this.envConfig.getEnvConfig())
      );
    }
    return replaceTokens(menu.links?.site.href, this.envConfig.getEnvConfig());
  }

  public isNewPage(menuItem): boolean {
    const newTab: string =
      menuItem.parameters['Open in New Tab (True or False)'];
    return newTab.toLowerCase() === 'true';
  }
}
